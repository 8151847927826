import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import cloudbase from "@cloudbase/js-sdk"; // 引入云开发环境
Vue.config.productionTip = false;
Vue.use(ElementUI);
const app = cloudbase.init({
  env: "sansui-6gznly9h0ddb6dcd",
});
Vue.prototype.$app = app;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
