import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/indexView"),
  },
  {
    path: "/carRentalModel/:type",
    name: "carRentalModel",
    component: () => import("../views/carRentalModelView"),
  },
  {
    path: "/carRentalModelDetail/:id",
    name: "carRentalModelDetail",
    component: () => import("../views/carRentalModelDetailView"),
  },
  {
    path: "/carRentalQuoteView",
    name: "carRentalQuoteView",
    component: () => import("../views/carRentalQuoteView"),
  },
  {
    path: "/carRentalProcessView",
    name: "carRentalProcessView",
    component: () => import("../views/carRentalProcessView"),
  },
  {
    path: "/carRentalNewsView",
    name: "carRentalNewsView",
    component: () => import("../views/carRentalNewsView"),
  },
  // {
  //   path: "/carRentalCaseView",
  //   name: "carRentalCaseView",
  //   component: () => import("../views/carRentalCaseView"),
  // },
  {
    path: "/carRentalCaseDetail/:id",
    name: "carRentalCaseDetail",
    component: () => import("../views/carRentalCaseDetailView"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/aboutView"),
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  // mode: "history", // 这里添加 mode
  routes,
});

export default router;
